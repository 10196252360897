<template>
    <div class="article-card">
        <NuxtLink v-if="article.data" :to="linkResolver(article)">
            <PrismicImage :field="article.data.thumbnail" :class="{thumbnail: true}" :imgix-params="{w: 700}" />
            <div class="hover-cta">
                <span>{{ t('resources.ctas.read') }}</span>
            </div>
        </NuxtLink>

        <div class="top-info">
            <CategoryPill :category="article.data?.category" />
            <div v-if="article.type === 'article'" class="date">
                <Calendar />
                <span>{{ releaseDate }}</span>
            </div>
        </div>
        <NuxtLink v-if="article.data" :to="linkResolver(article)">
            <div class="article-text">
                <h3 class="title">{{ article.data.title }}</h3>
                <PrismicRichText class="excerpt" :field="article.data.excerpt" />
            </div>
        </NuxtLink>
        <div class="tags">
            <TagPill v-for="tag in article.tags" :key="tag" :tag="tag" />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import {inject} from 'vue';
    import type {ArticleDocument, ArticleDocumentData, SuccessStoryDocument, SuccessStoryDocumentData} from '@prismicio-types';
    import {PrismicImage, PrismicRichText} from '@prismicio/vue';
    import {useContentHelpers} from '~/composables/useContentHelpers';
    import CategoryPill from '~/components/CategoryPill.vue';
    import Calendar from '~/components/icons/Calendar.vue';
    import TagPill from '~/components/TagPill.vue';
    import {USER_LOCALE} from '~/app/keys';
    import {useTranslation} from '#imports';
    import type {FilledContentRelationshipField} from '@prismicio/types';
    import linkResolver from '~/app/prismic/link-resolver';

    const locale = inject(USER_LOCALE);
    const props = defineProps<{
        article:
            | ArticleDocument
            | FilledContentRelationshipField<'article', string, ArticleDocumentData>
            | SuccessStoryDocument
            | FilledContentRelationshipField<'successStory', string, SuccessStoryDocumentData>;
    }>();
    const {t} = useTranslation();
    const {getPublicationDate} = useContentHelpers();
    const releaseDate = getPublicationDate(props.article)?.toLocaleDateString(locale, {year: 'numeric', month: 'long', day: 'numeric'});
</script>

<style lang="scss">
    @use '~/assets/styles/variables' as *;
    .article-card {
        width: auto;
        display: flex;
        flex-direction: column;
        position: relative;
        .thumbnail {
            width: 100%;
            height: 240px;
            object-fit: cover;

            @media (max-width: $mobile-breakpoint) {
                height: auto;
                max-height: 70vw;
            }
        }
        .hover-cta {
            position: absolute;
            top: 110px;
            left: 50%;
            transform: translateX(-50%) rotateZ(-8deg);
            padding: 5px 10px 3px;
            border-radius: var(--joy-core-radius-2);
            background-color: var(--joy-color-primary-50);

            z-index: 2;
            opacity: 0;
            transition: opacity var(--joy-transition-duration-default) var(--joy-transition-timing-function);
            span {
                font-family: var(--joy-font-family-title);
                font-size: var(--joy-font-size-primary-600);
                line-height: var(--joy-line-height-small);
                color: white;
            }
        }
        &:hover {
            .hover-cta {
                opacity: 1;
            }
        }
        .top-info {
            margin-top: var(--joy-core-spacing-4);
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: var(--joy-core-spacing-2);
        }
        .date {
            display: flex;
            align-items: center;
            gap: var(--joy-core-spacing-2);
            span {
                color: var(--joy-color-neutral-40);
            }
        }
        .article-text {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .title {
            margin-top: var(--joy-core-spacing-5);
        }
        .excerpt {
            margin-top: var(--joy-core-spacing-2);
            p {
                color: var(--joy-color-neutral-40);
                @include line-clamp(2);
            }
        }
        .tags {
            margin-top: var(--joy-core-spacing-4);
            display: flex;
            flex-wrap: wrap;
            gap: var(--joy-core-spacing-1);
        }
    }
</style>
